import { FC } from 'react'
import { useAppState } from '../../hooks/useAppState'

const logoSizeMap: Record<'large' | 'small', { width: number; height: number }> = {
  large: {
    width: 374,
    height: 100,
  },
  small: {
    width: 272,
    height: 49,
  },
}

export const LogoIcon: FC = () => {
  const {
    state: { isDesktopScreen },
  } = useAppState()
  const size = logoSizeMap[isDesktopScreen ? 'large' : 'small']

  return (
    <svg
      className={'logo'}
      width={size.width}
      height={size.height}
      viewBox="0 0 936 180"
      fill={'currentcolor'}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <g>
          <text x="0" y="0" id="text113" style={{ fontSize: 40 }}>
            <tspan id="tspan111" x="141" y="154">
              Powered by
            </tspan>
          </text>

          <g transform=" scale(0.5 0.5) translate(700 211)">
            <g transform="scale(0.8 0.8) translate(40 20)">
              <path
                className="cls-1"
                d="M125.26,21.97c-6.14-6.26-13.4-11.41-21.44-15.15-.33-.17-.66-.32-1-.47-1.58-.71-3.18-1.38-4.81-1.97-4.58-1.66-9.37-2.88-14.32-3.6-3.46-.5-7-.78-10.6-.78C32.79,0,0,32.79,0,73.09c0,31.4,19.9,58.23,47.76,68.56,3.28,1.21,6.66,2.2,10.14,2.93,4.91,1.04,9.99,1.6,15.2,1.6,27.76,0,51.96-15.56,64.33-38.42,5.59-10.32,8.77-22.14,8.77-34.68,0-19.89-7.99-37.94-20.92-51.13l-.02.02ZM21.8,112.2c-8.64-11.39-13.2-24.98-13.2-39.3,0-24.45,13.56-45.8,33.55-56.91.53-.29,1.05.41.61.82-1.83,1.72-3.58,3.56-5.25,5.52-11.59,13.55-18.26,31.98-18.32,50.58,0,24.14,16.15,50.2,42.24,50.2,23.28,0,42.23-22.52,42.23-50.2,0-13.01-4.36-25.5-11.81-34.76-.26-.33.05-.79.45-.67,12.59,3.72,22,18.2,22,35.44,0,15.22-5.52,30.37-15.15,41.57-9.98,11.61-23.38,18.01-37.72,18.01-15.1,0-29.56-7.41-39.63-20.28v-.02ZM125.54,33.63c8.63,11.36,13.18,24.94,13.17,39.25,0,24.45-13.56,45.8-33.55,56.92-.53.29-1.05-.41-.61-.82,1.83-1.72,3.58-3.56,5.25-5.52,11.59-13.55,18.26-31.98,18.32-50.58,0-24.14-16.15-50.2-42.24-50.2-23.28,0-42.23,22.52-42.23,50.2,0,13.01,4.36,25.5,11.81,34.77.26.33-.05.79-.45.67-12.59-3.72-22-18.19-22-35.42,0-15.22,5.52-30.37,15.15-41.56,9.98-11.61,23.38-18.01,37.73-18.01,15.09,0,29.54,7.4,39.65,20.3h0ZM96.01,72.9c0,17.24-8.38,32.12-20.38,38.8-1.49.83-3.32.72-4.7-.28l-2.57-1.87c-10.53-7.68-17.07-21.73-17.07-36.67,0-17.24,8.38-32.12,20.38-38.8,1.49-.83,3.32-.72,4.7.28l2.57,1.87c10.53,7.68,17.07,21.73,17.07,36.66h0Z"
              />
            </g>
            <g>
              <path
                className="cls-1"
                d="M206.83,50.96c-3.26-2.09-7.08-3.13-11.44-3.13s-8.19,1.04-11.48,3.13c-3.29,2.09-5.83,5.07-7.63,8.96s-2.7,8.52-2.7,13.89.9,9.93,2.7,13.82,4.34,6.88,7.63,8.99c3.29,2.11,7.11,3.17,11.48,3.17s8.18-1.05,11.44-3.17c3.26-2.11,5.8-5.11,7.63-8.99,1.82-3.89,2.73-8.49,2.73-13.82s-.91-10.06-2.73-13.92c-1.82-3.86-4.37-6.84-7.63-8.92h0ZM204.92,83.16c-.93,2.59-2.23,4.57-3.89,5.94-1.65,1.37-3.54,2.05-5.65,2.05s-4.01-.68-5.68-2.05c-1.68-1.37-2.99-3.35-3.92-5.94-.94-2.59-1.4-5.71-1.4-9.35s.47-6.76,1.4-9.35c.93-2.59,2.24-4.57,3.92-5.94,1.68-1.37,3.57-2.05,5.68-2.05s3.99.68,5.65,2.05c1.66,1.37,2.95,3.33,3.89,5.9s1.4,5.7,1.4,9.39-.47,6.76-1.4,9.35Z"
              />
              <path
                className="cls-1"
                d="M235.85,50.42l-12.33,45.55c-.4,1.49.72,2.95,2.25,2.95h5.51c1.06,0,1.99-.72,2.26-1.74l1.89-7.17c.27-1.03,1.2-1.74,2.26-1.74h12.57c1.07,0,2,.72,2.26,1.75l1.85,7.15c.27,1.03,1.2,1.75,2.26,1.75h5.78c1.54,0,2.66-1.46,2.25-2.95l-12.4-45.55c-.28-1.02-1.2-1.72-2.25-1.72h-11.9c-1.05,0-1.98.71-2.25,1.73h0ZM241.18,79.63c-1.53,0-2.65-1.45-2.26-2.93l2.07-7.86.75-3.13c.57-2.39,3.97-2.39,4.54,0l.75,3.13,2.03,7.87c.38,1.48-.73,2.92-2.26,2.92h-5.63,0Z"
              />
              <path
                className="cls-1"
                d="M321.01,51.03v3.96c0,1.29,1.05,2.34,2.34,2.34h3.75c1.29,0,2.34,1.05,2.34,2.34v28.28c0,1.29-1.05,2.34-2.34,2.34h-3.75c-1.29,0-2.34,1.05-2.34,2.34v3.96c0,1.29,1.05,2.34,2.34,2.34h22.6c1.29,0,2.34-1.05,2.34-2.34v-3.96c0-1.29-1.05-2.34-2.34-2.34h-3.75c-1.29,0-2.34-1.05-2.34-2.34v-28.28c0-1.29,1.05-2.34,2.34-2.34h3.75c1.29,0,2.34-1.05,2.34-2.34v-3.96c0-1.29-1.05-2.34-2.34-2.34h-22.6c-1.29,0-2.34,1.05-2.34,2.34Z"
              />
              <path
                className="cls-1"
                d="M395.58,77.72c-.98-1.85-2.28-3.33-3.89-4.46-1.61-1.13-3.36-2.01-5.25-2.66-1.9-.65-3.8-1.2-5.72-1.65-1.92-.45-3.68-.94-5.29-1.44-1.61-.5-2.89-1.16-3.85-1.98-.96-.81-1.44-1.89-1.44-3.24,0-1.87.71-3.31,2.12-4.32,1.41-1.01,3.2-1.51,5.36-1.51,1.49,0,2.89.25,4.14.9,1.65.87,2.78,2.2,3.41,3.15.43.65,1.16,1.03,1.94,1.03h6.3c1.63,0,2.8-1.64,2.19-3.16-.43-1.08-.97-2.08-1.64-3-1.77-2.45-4.09-4.32-6.94-5.61-2.85-1.3-5.98-1.94-9.39-1.94-3.69,0-6.91.65-9.64,1.94-2.73,1.29-4.86,3.06-6.37,5.29-1.51,2.23-2.27,4.74-2.27,7.52s.48,4.9,1.44,6.65,2.24,3.16,3.85,4.21c1.61,1.06,3.37,1.92,5.29,2.59,1.92.67,3.83,1.25,5.72,1.73,1.9.48,3.65,1,5.25,1.55,1.61.55,2.89,1.26,3.85,2.12s1.44,2.01,1.44,3.45c0,1.97-.73,3.5-2.19,4.61-1.46,1.1-3.42,1.65-5.86,1.65-3.02,0-5.32-.89-6.91-2.66-.66-.74-1.17-1.63-1.54-2.66-.37-1.01-1.3-1.69-2.38-1.69h-5.67c-1.56,0-2.67,1.49-2.25,2.99.38,1.36.89,2.64,1.54,3.81,1.58,2.83,3.84,5.01,6.76,6.55,2.93,1.53,6.41,2.3,10.43,2.3,3.65,0,6.9-.64,9.75-1.91,2.85-1.27,5.1-3.02,6.73-5.25s2.45-4.83,2.45-7.81-.49-5.24-1.48-7.09h.01Z"
              />
              <path
                className="cls-1"
                d="M309.49,77.72c-.98-1.85-2.28-3.33-3.89-4.46-1.61-1.13-3.36-2.01-5.25-2.66-1.9-.65-3.8-1.2-5.72-1.65-1.92-.45-3.68-.94-5.29-1.44-1.61-.5-2.89-1.16-3.85-1.98-.96-.81-1.44-1.89-1.44-3.24,0-1.87.71-3.31,2.12-4.32,1.41-1.01,3.2-1.51,5.36-1.51,1.49,0,2.89.25,4.14.9,1.65.87,2.78,2.2,3.41,3.15.43.65,1.16,1.03,1.94,1.03h6.3c1.63,0,2.8-1.64,2.19-3.16-.43-1.08-.97-2.08-1.64-3-1.77-2.45-4.09-4.32-6.94-5.61-2.85-1.3-5.98-1.94-9.39-1.94-3.69,0-6.91.65-9.64,1.94-2.73,1.29-4.86,3.06-6.37,5.29-1.51,2.23-2.27,4.74-2.27,7.52s.48,4.9,1.44,6.65,2.24,3.16,3.85,4.21c1.61,1.06,3.37,1.92,5.29,2.59,1.92.67,3.83,1.25,5.72,1.73,1.9.48,3.65,1,5.25,1.55,1.61.55,2.89,1.26,3.85,2.12s1.44,2.01,1.44,3.45c0,1.97-.73,3.5-2.19,4.61s-3.42,1.65-5.86,1.65c-3.02,0-5.32-.89-6.91-2.66-.66-.74-1.17-1.63-1.54-2.66s-1.3-1.69-2.38-1.69h-5.67c-1.56,0-2.67,1.49-2.25,2.99.38,1.36.89,2.64,1.54,3.81,1.58,2.83,3.84,5.01,6.76,6.55,2.93,1.53,6.41,2.3,10.43,2.3,3.65,0,6.9-.64,9.75-1.91,2.85-1.27,5.1-3.02,6.73-5.25s2.45-4.83,2.45-7.81-.49-5.24-1.48-7.09h.01Z"
              />
            </g>
            <g>
              <path
                className="cls-1"
                d="M521.65,99.77c-4.18,0-7.8-1.08-10.87-3.24-3.07-2.16-5.45-5.18-7.13-9.07-1.68-3.89-2.52-8.45-2.52-13.68s.84-9.8,2.52-13.72c1.68-3.91,4.06-6.94,7.13-9.07,3.07-2.14,6.7-3.2,10.87-3.2s7.8,1.07,10.87,3.2c3.07,2.14,5.45,5.16,7.13,9.07,1.68,3.91,2.52,8.48,2.52,13.72s-.84,9.79-2.52,13.68c-1.68,3.89-4.06,6.91-7.13,9.07-3.07,2.16-6.7,3.24-10.87,3.24ZM521.65,95.45c3.12,0,5.83-.9,8.14-2.7s4.09-4.33,5.36-7.6c1.27-3.26,1.91-7.06,1.91-11.38s-.64-8.17-1.91-11.41-3.06-5.76-5.36-7.56c-2.3-1.8-5.02-2.7-8.14-2.7s-5.83.9-8.14,2.7c-2.3,1.8-4.09,4.33-5.36,7.6-1.27,3.26-1.91,7.06-1.91,11.38s.64,8.11,1.91,11.38c1.27,3.26,3.06,5.8,5.36,7.6,2.3,1.8,5.01,2.7,8.14,2.7Z"
              />
              <g>
                <path
                  className="cls-1"
                  d="M449.29,74.72c-.21-.15-.43-.29-.64-.42-1.28-.79-1.39-2.69-.16-3.57.24-.17.47-.35.7-.55,1.34-1.15,2.35-2.54,3.02-4.18.67-1.63,1.01-3.34,1.01-5.11,0-3.7-1.43-6.66-4.28-8.89s-6.78-3.35-11.77-3.35h-15.55c-1.19,0-2.16.97-2.16,2.16v45.94c0,1.19.97,2.16,2.16,2.16h16.34c3.41,0,6.35-.58,8.82-1.73,2.47-1.15,4.38-2.76,5.72-4.82,1.34-2.06,2.02-4.46,2.02-7.2,0-2.4-.49-4.48-1.48-6.23-.98-1.75-2.23-3.16-3.74-4.21ZM424.19,55.13c0-1.19.97-2.16,2.16-2.16h10.73c3.36,0,6.02.75,7.99,2.23,1.97,1.49,2.95,3.62,2.95,6.41s-.97,5-2.92,6.66c-1.94,1.66-4.62,2.48-8.03,2.48h-10.73c-1.19,0-2.16-.97-2.16-2.16v-13.46ZM446.26,91.96c-2.04,1.75-4.84,2.63-8.39,2.63h-11.52c-1.19,0-2.16-.97-2.16-2.16v-15.19c0-1.19.97-2.16,2.16-2.16h10.44c3.94,0,7.01.85,9.22,2.56,2.21,1.7,3.31,4.12,3.31,7.24s-1.02,5.34-3.06,7.09Z"
                />
                <path
                  className="cls-1"
                  d="M467.7,48.66h-.5c-1.19,0-2.16.97-2.16,2.16v45.94c0,1.19.97,2.16,2.16,2.16h28.15c1.19,0,2.16-.97,2.16-2.16h0c0-1.19-.97-2.16-2.16-2.16h-23.33c-1.19,0-2.16-.97-2.16-2.16v-41.62c0-1.19-.97-2.16-2.16-2.16Z"
                />
                <path
                  className="cls-1"
                  d="M582.97,89.19c-1.25,1.97-2.88,3.5-4.9,4.61-2.02,1.1-4.37,1.66-7.06,1.66-3.07,0-5.83-.89-8.28-2.66-2.45-1.78-4.38-4.28-5.8-7.52s-2.12-7.07-2.12-11.48.67-8.11,2.02-11.38c1.34-3.26,3.24-5.8,5.69-7.6,2.45-1.8,5.3-2.7,8.57-2.7,3.79,0,6.94,1.13,9.43,3.38,2.1,1.9,3.47,4.33,4.11,7.28.22,1,1.08,1.72,2.11,1.72h.97c1.4,0,2.41-1.31,2.1-2.68-.52-2.3-1.41-4.36-2.67-6.18-1.73-2.5-3.95-4.43-6.66-5.8-2.71-1.37-5.82-2.05-9.32-2.05-4.18,0-7.88,1.06-11.12,3.17-3.24,2.11-5.77,5.11-7.6,9-1.83,3.89-2.74,8.47-2.74,13.75,0,4.94.85,9.38,2.56,13.32,1.7,3.94,4.16,7.04,7.38,9.32,3.22,2.28,7.01,3.42,11.38,3.42,3.7,0,6.95-.72,9.76-2.16s5.05-3.48,6.73-6.12c1.24-1.94,2.11-4.1,2.62-6.49.29-1.36-.71-2.66-2.11-2.66h-.99c-1.05,0-1.93.77-2.12,1.8-.34,1.86-.98,3.54-1.93,5.04Z"
                />
                <path
                  className="cls-1"
                  d="M629.99,48.66h-.35c-.57,0-1.13.23-1.53.64l-16.29,16.36-3.33,3.46c-1.36,1.41-3.75.43-3.72-1.53l.06-4.31v-12.46c0-1.19-.97-2.16-2.16-2.16h-.43c-1.19,0-2.16.97-2.16,2.16v45.94c0,1.19.97,2.16,2.16,2.16h.43c1.19,0,2.16-.97,2.16-2.16v-16.47c0-.57.22-1.11.62-1.52l4.4-4.47c.93-.95,2.49-.83,3.28.23l17.33,23.5c.41.55,1.05.88,1.74.88h.68c1.77,0,2.79-2.02,1.74-3.44l-18.44-24.98c-.63-.86-.55-2.04.2-2.8l15.14-15.35c1.35-1.37.38-3.68-1.54-3.68Z"
                />
                <path
                  className="cls-1"
                  d="M664.68,85.01l-.88,2.76c-.64,2.01-3.49,2.01-4.12,0l-.91-2.9-12.45-34.78c-.31-.86-1.12-1.43-2.03-1.43h-.54c-1.49,0-2.53,1.47-2.04,2.88l16.19,45.94c.3.86,1.12,1.44,2.04,1.44h3.42c.92,0,1.73-.58,2.04-1.44l16.19-45.94c.5-1.41-.55-2.88-2.04-2.88h-.39c-.91,0-1.73.57-2.03,1.43l-12.45,34.92Z"
                />
                <path
                  className="cls-1"
                  d="M718.95,51c-3.07-2.14-6.7-3.2-10.87-3.2s-7.8,1.07-10.87,3.2c-3.07,2.14-5.45,5.16-7.13,9.07-1.68,3.91-2.52,8.48-2.52,13.72s.84,9.79,2.52,13.68c1.68,3.89,4.06,6.91,7.13,9.07,3.07,2.16,6.7,3.24,10.87,3.24s7.8-1.08,10.87-3.24c3.07-2.16,5.45-5.18,7.13-9.07,1.68-3.89,2.52-8.45,2.52-13.68s-.84-9.8-2.52-13.72c-1.68-3.91-4.06-6.94-7.13-9.07ZM721.58,85.16c-1.27,3.26-3.06,5.8-5.36,7.6s-5.02,2.7-8.14,2.7-5.83-.9-8.14-2.7c-2.3-1.8-4.09-4.33-5.36-7.6-1.27-3.26-1.91-7.06-1.91-11.38s.64-8.11,1.91-11.38c1.27-3.26,3.06-5.8,5.36-7.6,2.3-1.8,5.01-2.7,8.14-2.7s5.83.9,8.14,2.7c2.3,1.8,4.09,4.32,5.36,7.56s1.91,7.04,1.91,11.41-.64,8.11-1.91,11.38Z"
                />
                <path
                  className="cls-1"
                  d="M734.87,52.97h12.89c1.19,0,2.16.97,2.16,2.16v41.62c0,1.19.97,2.16,2.16,2.16h.5c1.19,0,2.16-.97,2.16-2.16v-41.62c0-1.19.97-2.16,2.16-2.16h12.89c1.19,0,2.16-.97,2.16-2.16h0c0-1.19-.97-2.16-2.16-2.16h-34.92c-1.19,0-2.16.97-2.16,2.16h0c0,1.19.97,2.16,2.16,2.16Z"
                />
                <path
                  className="cls-1"
                  d="M785.56,92.43v-15.26c0-1.19.97-2.16,2.16-2.16h17.42c1.19,0,2.16-.97,2.16-2.16h0c0-1.19-.97-2.16-2.16-2.16h-17.42c-1.19,0-2.16-.97-2.16-2.16v-13.39c0-1.19.97-2.16,2.16-2.16h26.21c1.19,0,2.16-.97,2.16-2.16h0c0-1.19-.97-2.16-2.16-2.16h-30.96c-1.19,0-2.16.97-2.16,2.16v45.94c0,1.19.97,2.16,2.16,2.16h31.25c1.19,0,2.16-.97,2.16-2.16h0c0-1.19-.97-2.16-2.16-2.16h-26.5c-1.19,0-2.16-.97-2.16-2.16Z"
                />
              </g>
            </g>
          </g>
          <g transform="scale(0.174 0.174) translate(0 72)">
            <g transform="matrix(1,0,0,1,-236.67,-2845.95)">
              <path d="M562.155,2847.07C549,2851.07 541.994,2859.08 538.705,2873.66L537.132,2880.67L527.123,2882.53C479.938,2891.11 427.32,2913.56 395.291,2938.44L391.859,2941.15L396.149,2948.16C398.436,2952.02 401.868,2959.31 403.727,2964.32C407.302,2973.76 414.165,2982.76 423.602,2990.2L429.178,2994.63L441.046,2985.48C447.623,2980.47 455.059,2975.18 457.632,2973.76C461.779,2971.32 462.208,2970.32 462.208,2964.18C462.208,2949.16 471.359,2941.87 489.089,2942.87C490.805,2942.87 494.666,2945.16 497.525,2947.88L502.816,2952.74L512.253,2950.16C517.4,2948.73 524.406,2947.16 527.981,2946.59C531.556,2946.16 536.703,2945.16 539.42,2944.44C542.137,2943.59 549.429,2943.01 555.434,2943.01L566.444,2943.01L566.873,2929.72L567.302,2916.56L573.45,2916.13L579.456,2915.7L579.456,2928.57C579.456,2943.59 577.883,2942.3 598.33,2943.59C605.193,2944.01 616.775,2945.73 624.067,2947.45C631.359,2949.16 638.795,2950.88 640.653,2951.31C642.941,2951.74 644.657,2951.02 646.087,2949.02C648.947,2944.87 659.813,2941.01 665.533,2942.16C676.829,2944.44 686.695,2956.45 684.836,2965.75C683.835,2970.18 686.123,2972.18 714.577,2992.34C716.007,2993.34 718.866,2991.77 724.872,2986.48C731.735,2980.47 734.595,2976.47 741.744,2962.6C746.462,2953.45 751.181,2944.73 752.182,2943.01C753.898,2940.3 753.04,2939.58 739.742,2930.86C708.857,2910.56 660.671,2890.82 625.926,2884.1C617.347,2882.53 609.34,2880.96 608.482,2880.67C607.481,2880.39 606.623,2878.1 606.623,2875.52C606.48,2864.51 595.184,2850.36 584.174,2847.36C578.026,2845.64 567.588,2845.5 562.155,2847.07Z" />
              <path d="M335.809,2939.44C311.359,2948.16 303.065,2978.33 319.938,2997.06C328.66,3006.79 338.812,3010.93 351.537,3009.79C356.542,3009.5 361.689,3008.22 362.976,3007.22C364.835,3005.64 378.133,3018.37 461.207,3101.44L557.15,3197.39L463.638,3290.47C412.163,3341.51 369.268,3383.41 368.41,3383.41C367.552,3383.41 364.835,3382.41 362.404,3381.12C351.68,3375.83 336.238,3377.55 325.943,3385.41C319.08,3390.7 312.645,3401.43 311.216,3410.15C309.071,3424.16 318.508,3440.03 332.949,3447.18C339.527,3450.47 342.1,3450.9 349.107,3450.18C369.982,3448.32 383.995,3432.74 384.281,3410.72L384.424,3400.28L478.365,3306.34L572.306,3212.54L761.619,3401.28L761.19,3410.86C760.189,3437.03 783.067,3455.62 807.517,3448.47C828.965,3442.32 839.832,3417.72 829.823,3397.99C828.25,3394.85 823.96,3389.7 820.243,3386.41C809.805,3377.26 798.652,3375.4 785.068,3380.55C780.922,3382.12 776.918,3383.41 776.06,3383.41C774.917,3383.41 603.477,3213.4 592.181,3200.96L588.178,3196.53L681.69,3103.16C733.165,3051.68 776.06,3009.22 777.204,3008.5C778.348,3007.93 781.351,3008.07 784.068,3008.79C791.503,3010.93 803.943,3010.36 810.949,3007.5C818.241,3004.5 826.963,2996.35 830.681,2988.91C834.112,2981.91 834.112,2965.46 830.681,2959.17C821.244,2941.73 804.371,2934.01 786.641,2939.15C767.338,2944.87 756.757,2965.03 761.762,2987.05L762.763,2991.91L667.535,3086.86L572.164,3181.8L477.078,3086.57C393.289,3002.93 381.993,2991.06 382.994,2988.34C383.709,2986.62 384.138,2980.62 384.138,2975.19C384.138,2967.32 383.423,2963.6 380.706,2958.17C371.984,2941.01 353.253,2933.15 335.809,2939.44Z" />
              <path d="M535.845,2956.03C527.981,2957.46 518.115,2959.6 513.826,2960.61C506.247,2962.46 506.104,2962.61 504.389,2969.18C503.388,2972.9 501.529,2977.34 499.956,2978.91C493.665,2985.91 480.939,2987.92 472.217,2983.2L467.212,2980.62L455.059,2988.92C448.338,2993.49 441.332,2998.5 439.33,3000.07L435.756,3002.93L504.246,3071.42L572.592,3139.91L640.51,3071.85C688.41,3023.8 707.999,3003.22 707.142,3001.93C706.57,3000.93 699.849,2995.64 692.414,2990.2L678.83,2980.48L674.398,2983.2C668.679,2986.63 656.525,2986.77 650.948,2983.63C646.23,2980.77 642.798,2975.19 641.225,2968.04C640.224,2963.18 639.51,2962.46 633.79,2960.75C624.21,2957.89 594.326,2953.03 586.462,2953.03L579.456,2953.03L579.456,2981.62C579.456,3009.93 579.456,3010.22 582.744,3012.37C590.323,3017.23 591.61,3027.81 585.461,3034.67C582.172,3038.39 580.028,3039.39 574.022,3039.96C567.016,3040.53 566.301,3040.25 561.583,3035.53C558.008,3031.95 556.578,3029.24 556.578,3026.09C556.578,3020.52 559.867,3013.94 564.013,3010.94C567.159,3008.79 567.302,3007.93 567.731,2980.91L568.303,2953.03L559.152,2953.17C554.29,2953.17 543.709,2954.46 535.845,2956.03Z" />
              <path d="M311.501,3027.23C291.913,3059.26 274.468,3111.02 271.18,3146.05C269.893,3159.49 269.75,3159.92 263.744,3160.92C256.595,3161.92 249.732,3166.64 243.869,3174.36C234.861,3186.23 234.289,3199.81 242.44,3213.54C247.301,3221.69 255.737,3227.41 264.745,3228.98L269.75,3229.7L272.895,3248.29C276.041,3266.87 279.616,3282.17 284.191,3295.47C292.198,3319.21 314.933,3367.68 318.079,3367.68C318.794,3367.68 321.224,3366.53 323.226,3364.96C328.374,3361.39 336.953,3359.1 345.818,3359.1C353.253,3359.1 353.253,3359.1 364.406,3347.52C370.697,3341.23 375.702,3335.22 375.559,3334.36C375.416,3333.36 371.698,3327.5 367.123,3321.21C362.69,3314.92 358.544,3308.91 358.258,3307.91C357.829,3306.91 354.683,3306.2 349.679,3306.2C345.389,3306.2 339.956,3305.19 337.668,3303.91C332.949,3301.48 327.802,3292.18 327.802,3286.18C327.802,3280.89 332.806,3271.02 336.81,3268.3L340.384,3266.02L336.81,3255.01C332.806,3242.42 329.232,3220.55 329.232,3208.54L329.232,3200.39L306.354,3200.39L306.354,3188.95L327.516,3188.95L328.517,3177.08C329.375,3167.5 332.234,3150.77 337.239,3126.18C337.668,3123.89 336.81,3121.6 334.522,3119.03C329.375,3113.45 327.802,3109.73 327.802,3102.73C327.802,3090.43 338.24,3080.99 350.679,3082.14C356.971,3082.71 357.4,3082.42 359.83,3077.42C361.26,3074.56 364.978,3068.7 367.981,3064.69C371.126,3060.55 373.557,3056.54 373.271,3055.83C372.985,3055.11 367.552,3049.11 360.974,3042.53C349.822,3031.38 348.535,3030.52 341.671,3029.66C333.092,3028.52 323.655,3024.66 320.796,3021.22C319.795,3019.8 318.365,3018.79 317.65,3018.79C317.078,3018.79 314.218,3022.66 311.501,3027.23Z" />
              <path d="M822.245,3022.37C815.381,3027.09 811.092,3028.66 802.084,3029.66C795.22,3030.52 794.22,3031.24 784.068,3042.1C778.062,3048.39 772.772,3054.26 772.2,3055.26C771.485,3056.26 772.772,3059.26 775.203,3062.26C777.49,3065.12 781.494,3070.7 783.925,3074.56C788.357,3081.42 788.786,3081.71 794.791,3081.71C807.803,3081.71 815.667,3088.43 817.526,3101.01C818.67,3108.16 816.668,3113.74 811.092,3118.89L806.802,3122.89L808.947,3131.33C810.091,3135.9 811.378,3141.48 811.807,3143.91C812.093,3146.2 812.808,3149.77 813.237,3151.77C814.237,3157.2 815.095,3164.35 816.096,3177.08L817.097,3188.95L839.689,3188.95L839.689,3200.39L816.811,3200.39L816.668,3205.39C816.525,3208.11 816.382,3210.82 816.239,3211.4C816.096,3212.11 815.381,3217.12 814.809,3222.69C814.237,3228.27 811.95,3239.99 809.805,3248.72L805.801,3264.73L809.376,3267.88C815.381,3273.45 818.527,3282.17 816.954,3289.04C814.237,3301.19 807.803,3306.62 796.364,3306.48L788.929,3306.34L779.635,3319.78C774.631,3327.07 770.198,3333.79 769.912,3334.65C769.626,3335.51 774.345,3341.37 780.35,3347.52L791.217,3358.67L802.227,3359.39C811.092,3359.96 814.666,3360.82 820.243,3364.11L827.106,3368.11L829.68,3364.82C830.967,3363.1 837.115,3351.66 843.12,3339.65C858.42,3309.2 871.146,3269.02 874.72,3240.14C875.864,3230.99 876.579,3230.27 884.729,3227.84C900.315,3223.12 909.037,3210.97 909.037,3193.81C909.037,3185.23 908.465,3182.94 905.033,3177.8C900.029,3170.07 890.735,3163.35 883.728,3162.35C880.726,3161.78 878.009,3161.07 877.723,3160.49C877.437,3160.07 876.865,3156.35 876.293,3152.49C874.863,3140.19 871.432,3123.75 866.999,3107.45C865.283,3101.58 863.71,3095.72 863.424,3094.29C859.564,3079.28 837.544,3031.09 830.824,3022.66L827.535,3018.65L822.245,3022.37Z" />
              <path d="M379.705,3066.98C378.848,3068.41 375.273,3073.7 371.698,3078.7L365.407,3087.86L367.838,3092.29C372.127,3099.87 372.556,3105.16 369.41,3112.31C366.265,3119.46 359.545,3124.6 353.253,3124.6C349.822,3124.6 349.107,3125.46 347.248,3132.04C344.102,3143.05 340.813,3163.64 340.242,3177.08L339.527,3188.95L428.606,3188.95L432.61,3184.8C437.757,3179.22 443.477,3178.08 450.197,3181.23C457.203,3184.51 460.349,3191.66 458.061,3199.24C454.916,3209.39 440.903,3212.54 433.611,3204.68L429.607,3200.39L339.527,3200.39L340.099,3209.25C341.671,3233.84 347.248,3261.01 351.251,3263.59C352.109,3264.16 354.254,3264.73 355.827,3264.73C359.83,3264.73 365.979,3269.59 368.839,3275.17C371.841,3281.03 371.698,3290.04 368.696,3296.04C366.265,3300.62 366.265,3300.9 369.839,3305.91C371.841,3308.91 376.417,3314.92 379.991,3319.49L386.569,3327.64L391.859,3321.64C394.862,3318.2 423.459,3289.18 455.488,3256.87C487.516,3224.69 513.54,3197.81 513.397,3197.24C513.111,3196.53 381.993,3064.55 381.421,3064.55C381.421,3064.55 380.706,3065.69 379.705,3066.98Z" />
              <path d="M734.595,3092.01C719.581,3107.17 690.126,3137.19 668.822,3158.5L630.358,3197.53L695.131,3261.88C730.877,3297.19 760.475,3326.22 760.904,3326.22C761.333,3326.22 764.479,3322.07 767.91,3317.21C771.199,3312.35 775.345,3306.63 776.918,3304.63L779.921,3300.91L776.918,3295.05C773.058,3287.47 773.201,3281.18 777.204,3274.03C779.778,3269.45 781.78,3267.88 787.213,3266.02C790.931,3264.88 794.22,3263.45 794.648,3263.02C796.221,3261.45 803.657,3227.42 804.657,3217.84C805.086,3212.55 805.944,3206.54 806.23,3204.25L807.088,3200.39L761.19,3200.39C725.015,3200.39 715.149,3200.82 714.291,3202.25C710.716,3208.11 701.136,3210.69 694.845,3207.4C683.406,3201.54 684.55,3185.38 696.704,3180.38C702.137,3178.09 706.856,3179.37 713.004,3184.66L717.865,3188.95L807.088,3188.95L806.087,3179.23C804.943,3167.36 804.086,3160.64 803.228,3156.07C802.799,3154.07 802.227,3150.92 801.798,3148.92C801.369,3146.92 800.082,3140.62 798.652,3134.91C796.507,3125.47 795.935,3124.61 792.647,3124.61C778.205,3124.61 769.483,3106.88 776.775,3092.58L779.492,3087.15L773.916,3079C768.053,3070.7 763.049,3064.56 762.191,3064.56C761.905,3064.56 749.465,3077 734.595,3092.01Z" />
              <path d="M506.247,3320.35C470.215,3356.38 440.76,3386.41 440.76,3387.12C440.76,3387.84 445.479,3391.56 451.055,3395.56C467.069,3406.71 466.64,3406.57 473.075,3403.43C483.084,3398.28 494.237,3400.42 500.671,3408.57C502.387,3410.72 503.674,3413.86 503.674,3415.58C503.674,3417.29 504.532,3419.44 505.532,3420.3C508.249,3422.44 540.85,3429.02 554.433,3430.02L566.587,3431.02L566.587,3410.57C566.587,3390.56 566.587,3390.27 563.012,3387.41C556.578,3382.41 555.148,3377.97 557.436,3370.25C559.152,3364.53 560.296,3363.25 565.872,3360.53C573.593,3356.81 578.884,3357.53 584.317,3363.25C591.895,3371.11 591.467,3379.97 583.173,3387.55C579.456,3390.99 579.456,3391.27 579.456,3411.72L579.456,3432.31L583.459,3431.45C585.604,3431.16 591.181,3430.31 595.899,3429.88C600.475,3429.31 605.622,3428.59 607.338,3428.3C608.911,3427.88 614.773,3426.73 620.207,3425.59C641.511,3421.01 640.081,3421.73 642.226,3415.15C646.087,3402.71 662.101,3396.7 673.254,3403.28L678.545,3406.43L688.696,3399.56C694.273,3395.85 700.278,3391.7 702.137,3390.41C705.283,3387.98 704.997,3387.7 639.224,3321.35C602.762,3284.75 572.735,3254.72 572.306,3254.72C571.878,3254.86 542.137,3284.32 506.247,3320.35Z" />
              <path d="M707.285,3399.28C704.139,3401.71 697.99,3406.29 693.558,3409.29C685.98,3414.44 685.265,3415.44 685.265,3420.16C685.265,3427.31 680.117,3437.46 674.684,3440.89C671.252,3443.03 668.393,3443.46 661.1,3443.03C651.806,3442.6 649.376,3441.18 644.514,3432.88C643.227,3430.74 642.369,3430.74 633.075,3433.31C627.642,3434.88 620.207,3436.46 616.632,3437.03C613.2,3437.6 609.912,3438.17 609.483,3438.46C608.625,3439.03 601.19,3440.03 588.464,3441.32L579.456,3442.32L579.456,3473.49L566.587,3473.49L566.587,3457.76C566.587,3442.46 566.444,3442.03 563.441,3442.03C557.722,3441.89 544.71,3440.32 533.7,3438.32C519.116,3435.74 516.828,3435.31 509.107,3433.02C502.673,3431.02 502.53,3431.02 500.957,3434.31C498.526,3439.74 491.091,3443.46 482.941,3443.46C470.358,3443.32 463.638,3436.89 462.351,3423.44L461.493,3415.15L447.48,3405.71C439.759,3400.57 432.753,3396.28 431.752,3396.28C430.751,3396.28 424.603,3401.57 418.168,3408.14C409.875,3416.44 404.156,3423.87 399.294,3432.74C395.291,3439.6 392.288,3445.46 392.431,3445.75C392.717,3445.89 399.008,3449.9 406.444,3454.62C440.76,3476.06 474.934,3489.5 514.255,3497.08C524.549,3499.08 534.415,3501.09 536.274,3501.51C538.848,3502.09 539.42,3503.09 539.42,3507.95C539.42,3515.24 542.137,3521.68 548.142,3528.54C561.297,3543.41 585.89,3543.12 598.044,3527.82C602.905,3521.68 606.623,3512.38 606.623,3506.23C606.623,3502.66 607.195,3502.09 612.056,3501.37C648.232,3495.22 682.262,3484.64 713.862,3469.49C723.156,3465.05 744.032,3453.18 749.894,3449.04L754.041,3446.04L751.038,3441.6C749.465,3439.03 746.034,3433.17 743.746,3428.45C740.886,3423.02 734.738,3415.29 727.016,3407.29C720.153,3400.42 714.291,3394.85 713.719,3394.85C713.29,3394.85 710.287,3396.85 707.285,3399.28Z" />
              <g transform="matrix(1,0,0,1,236.67,2845.95)">
                <g transform="matrix(395.6,0,0,395.6,2477.54,495.755)">
                  <rect x="0.069" y="-0.424" width="0.332" height="0.12" />
                </g>
                <g>
                  <g transform="matrix(1,0,0,1,809.16,495.755)">
                    <g transform="matrix(395.6,0,0,395.6,0,0)">
                      <path d="M0.063,-0L0.217,-0L0.217,-0.236L0.346,-0.236C0.513,-0.236 0.611,-0.336 0.611,-0.481C0.611,-0.625 0.515,-0.727 0.35,-0.727L0.063,-0.727L0.063,-0ZM0.217,-0.359L0.217,-0.602L0.321,-0.602C0.409,-0.602 0.452,-0.553 0.452,-0.481C0.452,-0.409 0.409,-0.359 0.321,-0.359L0.217,-0.359Z" />
                    </g>
                    <g transform="matrix(395.6,0,0,395.6,256.241,0)">
                      <path d="M0.732,-0.364C0.732,-0.602 0.584,-0.737 0.391,-0.737C0.197,-0.737 0.05,-0.602 0.05,-0.364C0.05,-0.127 0.197,0.01 0.391,0.01C0.584,0.01 0.732,-0.126 0.732,-0.364ZM0.576,-0.364C0.576,-0.21 0.502,-0.126 0.391,-0.126C0.279,-0.126 0.206,-0.21 0.206,-0.364C0.206,-0.518 0.279,-0.601 0.391,-0.601C0.502,-0.601 0.576,-0.518 0.576,-0.364Z" />
                    </g>
                    <g transform="matrix(395.6,0,0,395.6,565.584,0)">
                      <path d="M0.672,-0.727L0.518,-0.727L0.518,-0.27L0.512,-0.27L0.198,-0.727L0.063,-0.727L0.063,-0L0.217,-0L0.217,-0.458L0.222,-0.458L0.539,-0L0.672,-0L0.672,-0.727Z" />
                    </g>
                    <g transform="matrix(395.6,0,0,395.6,856.384,0)">
                      <path d="M0.036,-0.6L0.258,-0.6L0.258,-0L0.41,-0L0.41,-0.6L0.633,-0.6L0.633,-0.727L0.036,-0.727L0.036,-0.6Z" />
                    </g>
                    <g transform="matrix(395.6,0,0,395.6,1120.77,0)">
                      <path d="M0.512,-0.727L0.512,-0.268C0.512,-0.185 0.454,-0.125 0.365,-0.125C0.276,-0.125 0.217,-0.185 0.217,-0.268L0.217,-0.727L0.063,-0.727L0.063,-0.255C0.063,-0.096 0.183,0.01 0.365,0.01C0.545,0.01 0.666,-0.096 0.666,-0.255L0.666,-0.727L0.512,-0.727Z" />
                    </g>
                    <g transform="matrix(395.6,0,0,395.6,1409.33,0)">
                      <path d="M0.458,-0.518L0.605,-0.518C0.603,-0.648 0.496,-0.737 0.335,-0.737C0.175,-0.737 0.058,-0.649 0.059,-0.517C0.059,-0.41 0.134,-0.348 0.257,-0.319L0.336,-0.299C0.415,-0.28 0.46,-0.257 0.46,-0.208C0.46,-0.155 0.409,-0.119 0.331,-0.119C0.251,-0.119 0.194,-0.156 0.189,-0.228L0.04,-0.228C0.044,-0.071 0.156,0.01 0.333,0.01C0.51,0.01 0.615,-0.075 0.615,-0.208C0.615,-0.329 0.523,-0.393 0.397,-0.422L0.332,-0.437C0.268,-0.452 0.216,-0.475 0.217,-0.527C0.217,-0.574 0.258,-0.609 0.333,-0.609C0.407,-0.609 0.452,-0.575 0.458,-0.518Z" />
                    </g>
                  </g>
                  <g transform="matrix(1,0,0,1,2642.32,495.755)">
                    <g transform="matrix(395.6,0,0,395.6,0,0)">
                      <path d="M0.207,-0.727L0.033,-0.727L0.256,-0.364L0.029,-0L0.205,-0L0.354,-0.248L0.36,-0.248L0.509,-0L0.686,-0L0.459,-0.364L0.681,-0.727L0.507,-0.727L0.36,-0.479L0.354,-0.479L0.207,-0.727Z" />
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
        <g transform="translate(120 0)">
          <path
            className="cls-1"
            d="M521.65,99.77c-4.18,0-7.8-1.08-10.87-3.24-3.07-2.16-5.45-5.18-7.13-9.07-1.68-3.89-2.52-8.45-2.52-13.68s.84-9.8,2.52-13.72c1.68-3.91,4.06-6.94,7.13-9.07,3.07-2.14,6.7-3.2,10.87-3.2s7.8,1.07,10.87,3.2c3.07,2.14,5.45,5.16,7.13,9.07,1.68,3.91,2.52,8.48,2.52,13.72s-.84,9.79-2.52,13.68c-1.68,3.89-4.06,6.91-7.13,9.07-3.07,2.16-6.7,3.24-10.87,3.24ZM521.65,95.45c3.12,0,5.83-.9,8.14-2.7s4.09-4.33,5.36-7.6c1.27-3.26,1.91-7.06,1.91-11.38s-.64-8.17-1.91-11.41-3.06-5.76-5.36-7.56c-2.3-1.8-5.02-2.7-8.14-2.7s-5.83.9-8.14,2.7c-2.3,1.8-4.09,4.33-5.36,7.6-1.27,3.26-1.91,7.06-1.91,11.38s.64,8.11,1.91,11.38c1.27,3.26,3.06,5.8,5.36,7.6,2.3,1.8,5.01,2.7,8.14,2.7Z"
          />
          <g>
            <path
              className="cls-1"
              d="M449.29,74.72c-.21-.15-.43-.29-.64-.42-1.28-.79-1.39-2.69-.16-3.57.24-.17.47-.35.7-.55,1.34-1.15,2.35-2.54,3.02-4.18.67-1.63,1.01-3.34,1.01-5.11,0-3.7-1.43-6.66-4.28-8.89s-6.78-3.35-11.77-3.35h-15.55c-1.19,0-2.16.97-2.16,2.16v45.94c0,1.19.97,2.16,2.16,2.16h16.34c3.41,0,6.35-.58,8.82-1.73,2.47-1.15,4.38-2.76,5.72-4.82,1.34-2.06,2.02-4.46,2.02-7.2,0-2.4-.49-4.48-1.48-6.23-.98-1.75-2.23-3.16-3.74-4.21ZM424.19,55.13c0-1.19.97-2.16,2.16-2.16h10.73c3.36,0,6.02.75,7.99,2.23,1.97,1.49,2.95,3.62,2.95,6.41s-.97,5-2.92,6.66c-1.94,1.66-4.62,2.48-8.03,2.48h-10.73c-1.19,0-2.16-.97-2.16-2.16v-13.46ZM446.26,91.96c-2.04,1.75-4.84,2.63-8.39,2.63h-11.52c-1.19,0-2.16-.97-2.16-2.16v-15.19c0-1.19.97-2.16,2.16-2.16h10.44c3.94,0,7.01.85,9.22,2.56,2.21,1.7,3.31,4.12,3.31,7.24s-1.02,5.34-3.06,7.09Z"
            />
            <path
              className="cls-1"
              d="M467.7,48.66h-.5c-1.19,0-2.16.97-2.16,2.16v45.94c0,1.19.97,2.16,2.16,2.16h28.15c1.19,0,2.16-.97,2.16-2.16h0c0-1.19-.97-2.16-2.16-2.16h-23.33c-1.19,0-2.16-.97-2.16-2.16v-41.62c0-1.19-.97-2.16-2.16-2.16Z"
            />
            <path
              className="cls-1"
              d="M582.97,89.19c-1.25,1.97-2.88,3.5-4.9,4.61-2.02,1.1-4.37,1.66-7.06,1.66-3.07,0-5.83-.89-8.28-2.66-2.45-1.78-4.38-4.28-5.8-7.52s-2.12-7.07-2.12-11.48.67-8.11,2.02-11.38c1.34-3.26,3.24-5.8,5.69-7.6,2.45-1.8,5.3-2.7,8.57-2.7,3.79,0,6.94,1.13,9.43,3.38,2.1,1.9,3.47,4.33,4.11,7.28.22,1,1.08,1.72,2.11,1.72h.97c1.4,0,2.41-1.31,2.1-2.68-.52-2.3-1.41-4.36-2.67-6.18-1.73-2.5-3.95-4.43-6.66-5.8-2.71-1.37-5.82-2.05-9.32-2.05-4.18,0-7.88,1.06-11.12,3.17-3.24,2.11-5.77,5.11-7.6,9-1.83,3.89-2.74,8.47-2.74,13.75,0,4.94.85,9.38,2.56,13.32,1.7,3.94,4.16,7.04,7.38,9.32,3.22,2.28,7.01,3.42,11.38,3.42,3.7,0,6.95-.72,9.76-2.16s5.05-3.48,6.73-6.12c1.24-1.94,2.11-4.1,2.62-6.49.29-1.36-.71-2.66-2.11-2.66h-.99c-1.05,0-1.93.77-2.12,1.8-.34,1.86-.98,3.54-1.93,5.04Z"
            />
            <path
              className="cls-1"
              d="M629.99,48.66h-.35c-.57,0-1.13.23-1.53.64l-16.29,16.36-3.33,3.46c-1.36,1.41-3.75.43-3.72-1.53l.06-4.31v-12.46c0-1.19-.97-2.16-2.16-2.16h-.43c-1.19,0-2.16.97-2.16,2.16v45.94c0,1.19.97,2.16,2.16,2.16h.43c1.19,0,2.16-.97,2.16-2.16v-16.47c0-.57.22-1.11.62-1.52l4.4-4.47c.93-.95,2.49-.83,3.28.23l17.33,23.5c.41.55,1.05.88,1.74.88h.68c1.77,0,2.79-2.02,1.74-3.44l-18.44-24.98c-.63-.86-.55-2.04.2-2.8l15.14-15.35c1.35-1.37.38-3.68-1.54-3.68Z"
            />
            <path
              className="cls-1"
              d="M664.68,85.01l-.88,2.76c-.64,2.01-3.49,2.01-4.12,0l-.91-2.9-12.45-34.78c-.31-.86-1.12-1.43-2.03-1.43h-.54c-1.49,0-2.53,1.47-2.04,2.88l16.19,45.94c.3.86,1.12,1.44,2.04,1.44h3.42c.92,0,1.73-.58,2.04-1.44l16.19-45.94c.5-1.41-.55-2.88-2.04-2.88h-.39c-.91,0-1.73.57-2.03,1.43l-12.45,34.92Z"
            />
            <path
              className="cls-1"
              d="M718.95,51c-3.07-2.14-6.7-3.2-10.87-3.2s-7.8,1.07-10.87,3.2c-3.07,2.14-5.45,5.16-7.13,9.07-1.68,3.91-2.52,8.48-2.52,13.72s.84,9.79,2.52,13.68c1.68,3.89,4.06,6.91,7.13,9.07,3.07,2.16,6.7,3.24,10.87,3.24s7.8-1.08,10.87-3.24c3.07-2.16,5.45-5.18,7.13-9.07,1.68-3.89,2.52-8.45,2.52-13.68s-.84-9.8-2.52-13.72c-1.68-3.91-4.06-6.94-7.13-9.07ZM721.58,85.16c-1.27,3.26-3.06,5.8-5.36,7.6s-5.02,2.7-8.14,2.7-5.83-.9-8.14-2.7c-2.3-1.8-4.09-4.33-5.36-7.6-1.27-3.26-1.91-7.06-1.91-11.38s.64-8.11,1.91-11.38c1.27-3.26,3.06-5.8,5.36-7.6,2.3-1.8,5.01-2.7,8.14-2.7s5.83.9,8.14,2.7c2.3,1.8,4.09,4.32,5.36,7.56s1.91,7.04,1.91,11.41-.64,8.11-1.91,11.38Z"
            />
            <path
              className="cls-1"
              d="M734.87,52.97h12.89c1.19,0,2.16.97,2.16,2.16v41.62c0,1.19.97,2.16,2.16,2.16h.5c1.19,0,2.16-.97,2.16-2.16v-41.62c0-1.19.97-2.16,2.16-2.16h12.89c1.19,0,2.16-.97,2.16-2.16h0c0-1.19-.97-2.16-2.16-2.16h-34.92c-1.19,0-2.16.97-2.16,2.16h0c0,1.19.97,2.16,2.16,2.16Z"
            />
            <path
              className="cls-1"
              d="M785.56,92.43v-15.26c0-1.19.97-2.16,2.16-2.16h17.42c1.19,0,2.16-.97,2.16-2.16h0c0-1.19-.97-2.16-2.16-2.16h-17.42c-1.19,0-2.16-.97-2.16-2.16v-13.39c0-1.19.97-2.16,2.16-2.16h26.21c1.19,0,2.16-.97,2.16-2.16h0c0-1.19-.97-2.16-2.16-2.16h-30.96c-1.19,0-2.16.97-2.16,2.16v45.94c0,1.19.97,2.16,2.16,2.16h31.25c1.19,0,2.16-.97,2.16-2.16h0c0-1.19-.97-2.16-2.16-2.16h-26.5c-1.19,0-2.16-.97-2.16-2.16Z"
            />
          </g>
        </g>
      </g>
    </svg>
  )
}
